export const AppRoutes = {
  home: "/",
  company: "/company",
  product: "/product",
  service: "/service",
  impressum: "/impressum",
} as const;

export type THomeNavRouteKey = "home" | "company" | "service" | "product";
export type THomeNavRoute = "/" | "/company" | "/service" | "/product";

export const HomeNavRoutes: THomeNavRoute[] = [
  AppRoutes.home,
  AppRoutes.company,
  AppRoutes.product,
  AppRoutes.service,
];
export const HomeNavRouteKeys: THomeNavRouteKey[] = [
  "home",
  "product",
  "service",
  "company",
];
